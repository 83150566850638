import { useParams } from "react-router-dom";
import Model from "./Model";
import { Link } from "react-router-dom";
import PersonaDetailImage from "./images/c4.svg";

function goBack() {
  window.history.back();
}

function PersonaDetail() {
  let { id, personaId } = useParams();

  return (
    <div className="guideline-container">
      <div className="header">
        <button className="back" alt="Back" onClick={goBack}><i className="fa fa-chevron-left"></i> Back</button>
      </div>
      <h1>{Model[id]["NAME"]} / {personaId}</h1>
      <div className="justify-center">
        <img alt="PMI" className="headerImage" src={PersonaDetailImage} />
      </div>
      <ul>
        {
          Model[id]["PERSONAS"][personaId]["TEXTS"].map((text, index) => (
            <h3 key={index}><li dangerouslySetInnerHTML={{__html: text}}></li></h3>
          )) 
        }
      </ul>
      <Link to={`/email/${id}/${Model[id]["PERSONAS"][personaId]["NODE_ID"]}/${Model[id]["PERSONAS"][personaId]["FLOW_ID"]}`}>
          <button className="full-width justify-space-between">
            Next Step
            <i className="fa fa-chevron-right"></i>
          </button>
        </Link>

    </div>
  );
}

export default PersonaDetail;
