import "./Guideline.css";
import GuidelineImage from "./images/c1.svg";

function goBack() {
  window.history.back();
}

function Guideline() {
  return (
    <div className="guideline-container">
      <div className="header">
        <button className="back" alt="Back" onClick={goBack}><i className="fa fa-chevron-left"></i> Back</button>
      </div>
      <h1>General Guidelines</h1>
      <div className="justify-center">
        <img alt="PMI" className="headerImage" src={GuidelineImage} />
      </div>
      <ol>
        <h3><li>When conducting the mystery shop you become the consumer, you are walking in their shoes and feeling their emotions - ultimately, your feedback to this survey will have an impact on the future experiences of our other customers.</li></h3>
        <h3><li>Before beginning your mystery shop make sure to read carefully your persona. It is important for you to stay in character. </li></h3>
        <h3><li><strong>Every persona will have a different mystery shopping task (you might be asked to buy the device, try the device or not complete any purchase). Please read it very careful as if you complete the wrong action (example buying the device instead of trying it) you may not be refunded.</strong></li></h3>
        <h3><li><strong>You must not open/unbox the device or HEETS after purchase-- you will be required to return the device following the normal returns process that customers would in that specific channel. Please carefully read the returns and expenses guidelines on our sharepoint page. Please note that failure to comply to the return standards could mean that you will not be refunded.</strong></li></h3>
        <h3><li>If in the persona you choose the action is to register with a TRY option, then you must complete the TRY BEFORE YOU BUY survey after 14 days.</li></h3>
        <h3><li>Remember to stay in character. Do not let the retailer or agent know that you work for PML or that you are conducting a mystery shop.</li></h3>
        <h3><li>Please answer the survey questions only after you have finished your mystery shop (or in the case of TRY BEFORE YOU BUY, complete the survey after 14 days).</li></h3>
        <h3><li>Take your time when answering the questions and fill out the survey to the best of your ability. For example, if asked the name of store and address, make sure to compile it correctly.</li></h3>
        <h3><li>If you are required to insert any credentials to complete your mystery shop please use your real details (name, surname, dob, phone number) and a yopmail email address with the following structure: <strong>name.surnameX@yopmail.com</strong> where X=1,2,3,4,... depending on the number of mystery shops you have conducted (you can check the inbox at this url <a class='hyper' target='_blank' rel='noreferrer' href='http://www.yopmail.com/en/'>http://www.yopmail.com/en/</a>). Please do not use your PMI email address for registration purposes!</li></h3>
        <h3>
          <li>If you need support or have any queries about the survey or the results, please email the CX team:</li>
          <ul>
            <li><a className="font-color-turquoise  text-underline" href="mailto:Nikhil.Menon@pmi.com">Nikhil.Menon@pmi.com</a></li>
            <li><a className="font-color-turquoise text-underline" href="mailto:Ortensia.Palmonella@pmi.com">Ortensia.Palmonella@pmi.com</a></li>
            <li><a className="font-color-turquoise text-underline" href="mailto:Marius.Ghios@pmi.com">Marius.Ghios@pmi.com</a></li>
          </ul>
        </h3>
      </ol>
    </div>
  )
}

export default Guideline;
