import { withRouter } from "react-router-dom";
import React from 'react';

class Email extends React.Component {
  constructor(props) {
    super(props);

    // const { id, nodeId, flowId} = useParams();
    // let { path, url } = useRouteMatch();
    const params = this.props.match.params;

    this.state = {
      nodeId: params.nodeId,
      flowId: params.flowId,
      error: true,
      touched: false,
      email: null
    };

    this.validateEmail = this.validateEmail.bind(this);
    this.navigateToFeedback = this.navigateToFeedback.bind(this);
    this.handleFocusOut = this.handleFocusOut.bind(this);
    this.goBack = this.goBack.bind(this);
  }

  goBack() {
    window.history.back();
  }

  navigateToFeedback(url) {
    const { nodeId, flowId, email } = this.state;
    const preloadedResponses = [{"question": "EMAIL", "answer": email}];
    const responses = btoa(JSON.stringify(preloadedResponses));

    window.location.replace(`https://web.pisano.co/web_feedback?node_id=${nodeId}&flow_id=${flowId}&responses=${responses}`);
  }

  validateEmail(e) {
    const email = e.target.value;

    const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!emailRegex.test(String(email).toLowerCase())) {
      this.setState({error: true });
    }
    else {
      const domain = email.split("@")[1];

      if (domain === "pmi.com") {
        this.setState({error: false, email: email});
      }
      else {
        this.setState({error: true });
      }
    }
  }

  handleFocusOut() {
    this.setState({touched: true})
  }

  render() {
    const { error, touched } = this.state;

    return (
      <div className="guideline-container">
        <div className="header">
          <button className="back" alt="Back" onClick={this.goBack}><i className="fa fa-chevron-left"></i> Back</button>
        </div>

        <h1>Email Validation</h1>
        <h3>Before we begin... Can you please input your PMI email address?</h3>

        <input className={error && touched ? "error" : ""} type="text" onBlur={this.handleFocusOut} onChange={this.validateEmail} placeholder="Your email address"></input>

        { error && touched && <p className="error-message">Please write your PMI email address</p> }
        <h4>By clicking "Start Survey" you confirm you accept the <a class="hyper" href="https://www.pisano.com/tospp" target="_blank" rel="noreferrer">Terms and Conditions</a></h4>
        <h4>Your personal data will be processed in accordance with our Employee Privacy Notice. You can find it on Oneplace - Featured Links, selecting "People and Culture" and "Pmi data privacy - employees"</h4>
        <button
          disabled={error}
          onClick={() => {this.navigateToFeedback()}}
          style={{marginTop: "2rem"}} className="full-width justify-space-between">
          Start Survey
          <i className="fa fa-chevron-right"></i>
        </button>
      </div>
    );
  }
}

export default withRouter(Email);
