import { Link } from "react-router-dom";
import ReminderImage from "./images/c2.svg";

function goBack() {
  window.history.back();
}

function Reminder() {
  return (
    <div className="guideline-container">
      <div className="header">
        <button className="back" alt="Back" onClick={goBack}><i className="fa fa-chevron-left"></i> Back</button>
      </div>
      <h1>Key Reminder</h1>
      <div className="justify-center">
        <img alt="PMI" className="headerImage" src={ReminderImage} />
      </div>
      <h3>Before you proceed make sure you have familiarized yourself with the mystery shopping guidelines.</h3>
      <h3>Remember to proceed to the selection of your persona before you conduct the mystery shop, then pause the app, conduct the mystery shop and do the survey only after you finished!</h3>
      <h3>Note that you will not be asked to purchase a device in every channel. The action that you must take will be specified in each persona. Failure to comply with the guidelines means that you may not be refunded.</h3>
      <Link to="/channels">
        <button className="justify-space-between full-width">Select Channel<i className="fa fa-chevron-right"></i></button>
      </Link>
    </div>
  )
}

export default Reminder;
