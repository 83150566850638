import { useParams } from "react-router-dom";
import Model from "./Model";
import { Link } from "react-router-dom";
import PersonaImage from "./images/c3.svg";

function goBack() {
  window.history.back();
}

function Personas() {
  let { id } = useParams();

  return (
    <div className="guideline-container">
      <div className="header">
        <button className="back" alt="Back" onClick={goBack}><i className="fa fa-chevron-left"></i> Back</button>
      </div>
      <h1>{Model[id]["NAME"]} / Select Persona</h1>
      <div className="justify-center margin-bottom-1em">
        <img alt="PMI" className="headerImage" src={PersonaImage} />
      </div>
      {
        Object.keys(Model[id]["PERSONAS"]).map(persona => (
          <Link key={persona} to={`/channels/${id}/personas/${persona}`}>
            <button className="full-width ghost justify-space-between">
              {persona}
              <i className="fa fa-chevron-right"></i>
            </button>
          </Link>
        ))
      }
    </div>
  )
}

export default Personas;
