import Model from "./Model";
import { Link } from "react-router-dom";

function goBack() {
  window.history.back();
}

function Channels() {
  return (
    <div className="guideline-container">
      <div className="header">
        <button className="back" alt="Back" onClick={goBack}><i className="fa fa-chevron-left"></i> Back</button>
      </div>
      <h1>Select Channel</h1>
      {Object.keys(Model).map(key => (
        <Link key={key} to={`/channels/${key}/personas`}>
          <button
            className="full-width justify-space-between"
            style={{backgroundColor: Model[key]["COLOR"], border: "1px solid", borderColor: Model[key]["COLOR"], color: "#FFFFFF"}}>
            {Model[key]["NAME"]}
            <i className={`fa ${Model[key]["ICON"]}`}></i>
          </button>
        </Link>
      ))}
    </div>
  )
}

export default Channels;
