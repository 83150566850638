import { Link } from "react-router-dom";
import "./Home.css";

function Home() {
  return (
    <div className="full-width flex-column justify-space-between">
      <div className="home-container">
        <img alt="PMI" src="/logos/6.png" />
        <div className="full-width">
          <Link className="full-width" to="/reminder">
            <button className="full-width">Conduct a Mystery Shop<i className="fa fa-chevron-right"></i></button>
          </Link>
          <Link className="full-width" to="/guideline">
            <button className="full-width ghost">Read Mystery Shop Guidelines<i className="fa fa-chevron-right"></i></button>
          </Link>
        </div>
      </div>
      <Link className="justify-center" to="/cep">
        <button className="back text-underline justify-center">More about the Consumer Excellence Programme</button>
      </Link>
    </div>
  )
}

export default Home;
