import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect
} from "react-router-dom";

import './App.css';
import Home from './Home';
import Reminder from './Reminder';
import Channels from './Channels';
import Personas from './Personas';
import PersonaDetail from './PersonaDetail';
import Guideline from './Guideline';
import Email from './Email';
import Cep from './Cep';
import ScrollToTop from './ScrollToTop';

function App() {
  return (
    <Router>
      <ScrollToTop>
        <div className="container">
          <Switch>
            <Route exact path="/">
              <Home />
            </Route>
            <Route path="/reminder">
              <Reminder/>
            </Route>
            <Route path="/guideline">
              <Guideline/>
            </Route>
            <Route exact path="/channels">
              <Channels/>
            </Route>
            <Route exact path="/channels/:id/personas">
              <Personas/>
            </Route>
            <Route path="/cep">
              <Cep/>
            </Route>

            <Route path="/channels/:id/personas/:personaId">
              <PersonaDetail/>
            </Route>

            <Route path="/email/:id/:nodeId/:flowId">
              <Email />
            </Route>

            <Redirect to="/" />
          </Switch>
        </div>
      </ScrollToTop>
    </Router>
  );
}

export default App;
