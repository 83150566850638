const Model = {
  "GENERAL_TRADE": {
    "NAME": "General Trade",
    "COLOR": "#00D1D2",
    "ICON": "fa-users",
    "LOGO_INDEX": "2",
    "PERSONAS": {
      "LAS": {
        "NODE_ID": "25a615af-82da-4c9c-898f-20423eb4edcf",
        "FLOW_ID": "c0b58d42-7eb3-4671-9b6b-58461ef33db5",
        "TEXTS": [
          "You are a cigarette smoker. You have limited knowledge of RRPs, you are looking for an alternative to cigarettes and you have considered switching in the past. You are price sensitive, but you don't mind spending money if the quality is worth it.",
          "You will be required to register for a <strong>TRY BEFORE YOU BUY</strong> and to return the device by calling customer care after the end of the trial. <strong>Do not open the packaging and do not use the device.</strong> You will be refunded £10.",
          "For information on returns and expenses visit our sharepoint page. <strong>Failure to comply with the guidelines means that you may not be refunded.</strong>",
          "Please do not buy the device if the try option isn't available in General Trade.",
          "Note that the mystery shop begins from the outside of the shop - you will be asked questions on HNB and IQOS signage both inside and outside the shop. So please pay attention.",
          "For the purposes of this mystery shop if the retailer does not proactively talk about IQOS please engage with the retailer to spark the conversation around IQOS.",
          "If you are required to insert any credentials to complete your mystery shop please use your real details (name, surname, dob, phone number) and a yopmail email address with the following structure: <strong>name.surnameX@yopmail.com</strong> where X=1,2,3,4,... depending on the number of mystery shops you have conducted (you can check the inbox at this url <a class='hyper' target='_blank' href='http://www.yopmail.com/en/'>http://www.yopmail.com/en/</a>). Please do not use your PMI email address for registration purposes!"
        ]
      }
    }
  },
  "ECOMMERCE": {
    "NAME": "E-commerce",
    "COLOR": "#2A80B5",
    "ICON": "fa-shopping-cart",
    "LOGO_INDEX": "3",
    "PERSONAS": {
      "LAS": {
        "NODE_ID": "c2a12493-b33c-4e3e-a84b-2624649984d9",
        "FLOW_ID": "eb9519bc-d6af-43a5-9803-fb9fc2dbafb1",
        "TEXTS": [
          "You are a cigarette smoker and are looking for an alternative. You know that RRPs exist and you might even have heard of the name IQOS but you are not sure how to spell it, what it does or how it works.",
          "Note that the mystery shop begins from your preferred search engine. Be creative with your key word search, do not limit yourself to \"IQOS\" or \"heated tobacco\".",
          "You will be required to <strong>BUY</strong> a device and to return the device through the regular returns process. <strong>Do not open the packaging and do not use the device.</strong>",
          "For information on returns and expenses visit our sharepoint page. <strong>Failure to comply with the guidelines means that you may not be refunded.</strong>",
          "You will also be required as part of the Mystery Shop to use the chat functionality and to visit the FAQ.",
          "For the purposes of this mystery shop please try to remove your pre-existing knowledge about IQOS and conduct the online search as objectively as possible, taking the time you normally would, to research/buy a product you know nothing about.",
          "If you are required to insert any credentials to complete your mystery shop please use your real details (name, surname, dob, phone number) and a yopmail email address with the following structure: <strong>name.surnameX@yopmail.com</strong> where X=1,2,3,4,... depending on the number of mystery shops you have conducted (you can check the inbox at this url <a class='hyper' target='_blank' href='http://www.yopmail.com/en/'>http://www.yopmail.com/en/</a>). Please do not use your PMI email address for registration purposes!"
        ]
      }
    }
  },
  "VAPE_VPZ": {
    "NAME": "VAPE + VPZ",
    "COLOR": "#44546A",
    "ICON": "fa-handshake-o",
    "LOGO_INDEX": "3",
    "PERSONAS": {
      "VAPE LAS": {
        "NODE_ID": "7d68c22d-ad4a-40d2-b8bf-a6e86dbc71b2",
        "FLOW_ID": "e7fbf0ac-afbf-44a4-96da-ddcd208b086f",
        "TEXTS": [
          "You are a cigarette smoker. You have limited knowledge of RRPs, you are looking for an alternative to cigarettes and have tried vaping before - it didn't work for you. You are price sensitive, but you don’t mind spending money if the quality is worth it.",
          "You will be required to register for a <strong>TRY BEFORE YOU BUY</strong> and to return the device by calling customer care after the end of the trial. <strong>Do not open the packaging and do not use the device.</strong> You will be refunded £10.",
          "Please do not buy the device if the try option isn’t available.",
          "For information on returns and expenses visit our sharepoint page. <strong>Failure to comply with the guidelines means that you may not be refunded.</strong>",
          "Note that the mystery shop begins from the outside of the shop - you will be asked questions on HNB and IQOS signage both inside and outside the shop. So please pay attention.",
          "For the purposes of this mystery shop if the agent does not proactively talk about IQOS please engage with the retailer to spark the conversation around IQOS.",
          "If you are required to insert any credentials to complete your mystery shop please use your real details (name, surname, dob, phone number) and a yopmail email address with the following structure: <strong>name.surnameX@yopmail.com</strong> where X=1,2,3,4,... depending on the number of mystery shops you have conducted (you can check the inbox at this url <a class='hyper' target='_blank' href='http://www.yopmail.com/en/'>http://www.yopmail.com/en/</a>). Please do not use your PMI email address for registration purposes!"
        ]
      },
      "VPZ LAS": {
        "NODE_ID": "c0837162-18ee-42e5-b6af-30466e1fd747",
        "FLOW_ID": "2b152013-32bb-45c2-ae38-df506e5ef169",
        "TEXTS": [
          "You are a cigarette smoker. You have limited knowledge of RRPs, you are looking for an alternative to cigarettes and have tried vaping before - it didn't work for you. You are price sensitive, but you don’t mind spending money if the quality is worth it.",
          "You will be required to <strong>BUY</strong> a device and to return the device through the regular returns process. For VPZ this means returning it to the store you originally bought it from. <strong>Do not open the packaging and do not use the device.</strong>",
          "For information on returns and expenses visit our sharepoint page. <strong>Failure to comply with the guidelines means that you may not be refunded.</strong>",
          "Note that the mystery shop begins from the outside of the shop - you will be asked questions on HNB and IQOS signage both inside and outside the shop. So please pay attention.",
          "For the purposes of this mystery shop if the agent does not proactively talk about IQOS please engage with the retailer to spark the conversation around IQOS.",
          "If you are required to insert any credentials to complete your mystery shop please use your real details (name, surname, dob, phone number) and a yopmail email address with the following structure: <strong>name.surnameX@yopmail.com</strong> where X=1,2,3,4,... depending on the number of mystery shops you have conducted (you can check the inbox at this url <a class='hyper' target='_blank' href='http://www.yopmail.com/en/'>http://www.yopmail.com/en/</a>). Please do not use your PMI email address for registration purposes!"
        ]
      }
    }
  },
  "CUSTOMER_CARE": {
    "NAME": "Customer Care",
    "COLOR": "#E27D34",
    "ICON": "fa-headphones",
    "LOGO_INDEX": "4",
    "PERSONAS": {
      "LAU": {
        "NODE_ID": "db9c0ac8-0274-4fb3-a0b4-a880000f8c83",
        "FLOW_ID": "ae204f18-d689-4233-afe7-7591be9df641",
        "TEXTS": [
          "You have been using IQOS for the past couple of weeks. You own an IQOS 2.4+. Recently it's not been working properly and you now noticed that on your holder you have a red blinking light.",
          "If asked for a registered email, you do not know the email which you signed up with but you would still like assistance on your device malfunction."
        ]
      }
    }
  },
  "KEY_ACCOUNTS": {
    "NAME": "Key Accounts",
    "COLOR": "#A22F26",
    "ICON": "fa-key",
    "LOGO_INDEX": "6",
    "PERSONAS": {
      "LAS": {
        "NODE_ID": "58237cf0-face-4146-8e10-e551fdbb2f3a",
        "FLOW_ID": "17ac7594-97b1-4b80-a4de-42498111f9b2",
        "TEXTS": [
          "You are a cigarette smoker. You have limited knowledge of RRPs, you are looking for an alternative to cigarettes and you have considered in the past switching. For the purposes of this mystery shop proactively ask about alternatives to smoking. You are price sensitive, but you don't mind spending money if the quality is worth it.",
          "You will be required to register for a <strong>TRY BEFORE YOU BUY</strong> and to return the device by calling customer care after the end of the trial. <strong>Do not open the packaging and do not use the device.</strong> You will be refunded £10.",
          "Please do not buy the device if the try option isn’t available.",
          "For information on returns and expenses visit our sharepoint page. <strong>Failure to comply with the guidelines means that you may not be refunded.</strong>",
          "Note that the mystery shop begins from the outside of the shop - you will be asked questions on HNB and IQOS signage both inside and outside the shop. So please pay attention.",
          "If you are required to insert any credentials to complete your mystery shop please use your real details (name, surname, dob, phone number) and a yopmail email address with the following structure: <strong>name.surnameX@yopmail.com</strong> where X=1,2,3,4,... depending on the number of mystery shops you have conducted (you can check the inbox at this url <a class='hyper' target='_blank' href='http://www.yopmail.com/en/'>http://www.yopmail.com/en/</a>). Please do not use your PMI email address for registration purposes!"
        ]
      },
      "LAU": {
        "NODE_ID": "9c32fc02-6d07-45d9-b45e-b615b18b7440",
        "FLOW_ID": "067ae81a-e941-43d9-9333-08b193eed81d",
        "TEXTS": [
          "You are an IQOS user and are looking to buy some HEETS. Please pay attention to the flavors available in the store and the staff knowledge of HEETS.",
          "Please do not buy HEETS for the purpose of the mystery shop. You will not be refunded if you buy them."
        ]
      }
    }
  },
  "TRY": {
    "NAME": "Try Before You Buy (Day 14 Survey)",
    "COLOR": "#FFD244",
    "ICON": "fa-comments",
    "LOGO_INDEX": "6",
    "PERSONAS": {
      "LAS": {
        "NODE_ID": "c1a004a6-b3cc-4101-9d04-ef91bd276844",
        "FLOW_ID": "70f94dc4-4f40-467e-9339-66d294b5c248",
        "TEXTS": [
          "After having completed your mystery shop survey for the channel in which you enrolled in try - you need to wait for 14 days before proceeding on this survey.",
          "You will be required to track the communications you receive from IQOS as you will be asked some questions on them.",
          "Note that at the end of the Try period you will be required to return the IQOS by calling customer care. In this survey you will be asked questions about your interaction with an IQOS expert.",
          "If you are required to insert any credentials to complete your mystery shop please use your real details (name, surname, dob, phone number) and a yopmail email address with the following structure: <strong>name.surnameX@yopmail.com</strong> where X=1,2,3,4,... depending on the number of mystery shops you have conducted (you can check the inbox at this url <a class='hyper' target='_blank' href='http://www.yopmail.com/en/'>http://www.yopmail.com/en/</a>). Please do not use your PMI email address for registration purposes!"
        ]
      }
    }
  }
}

export default Model;
