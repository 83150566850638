import CepImage from "./images/c25.svg";
import IntegrityImage from "./images/2.png";
import ResolutionImage from "./images/5.png";
import ExpectationsImage from "./images/3.png";
import TimeImage from "./images/4.png";
import PersonalizationImage from "./images/1.png";
import EmpathyImage from "./images/6.png";

function goBack() {
  window.history.back();
}

function Cep() {
  return (
    <div className="guideline-container">
      <div className="header">
        <button className="back" alt="Back" onClick={goBack}><i className="fa fa-chevron-left"></i> Back</button>
      </div>
      <h1 className="font-color-turquoise">Consumer Excellence Programme [CEP]</h1>
      <div className="justify-center">
        <img alt="PMI" className="headerImage" src={CepImage} />
      </div>
      <h3>CEP is the way you can contribute to building a loyal and satisfied consumer base by experiencing first-hand what it is like to be an IQOS customer and thus identifying the areas in which we demonstrate mastery of customer experience and those in which there is room for improvements.</h3>
      <h2 className="font-color-turquoise text-align-center">The Consumer Performance Indicators</h2>
      <h3>There are six pillars which have been proven by a decade of research to be the universal set of qualities for an outstanding customer experience.</h3>
      <h2 className="text-align-center">Integrity</h2>
      <div className="justify-center">
        <img alt="PMI" className="cep-image" src={IntegrityImage} />
      </div>
      <h3>Acting with integrity and engendering trust. Trust is an outcome of consistent organizational behavior that demonstrates trustworthiness.</h3>

      <h2 className="text-align-center">Resolution</h2>
      <div className="justify-center">
        <img alt="PMI" className="cep-image" src={ResolutionImage} />
      </div>
      <h3>Turning a poor experience into a great one. Historically when it comes to resolution, organizations have majored on service recovery.</h3>

      <h2 className="text-align-center">Expectations</h2>
      <div className="justify-center">
        <img alt="PMI" className="cep-image" src={ExpectationsImage} />
      </div>
      <h3>Managing, meeting and exceeding customer expectations. Customers have needs and they also have expectations about how these needs will be met.</h3>

      <h2 className="text-align-center">Time and Effort</h2>
      <div className="justify-center">
        <img alt="PMI" className="cep-image" src={TimeImage} />
      </div>
      <h3>Minimizing customer effort and creating frictionless processes. Customers are time poor and increasingly are looking for instant gratification.</h3>

      <h2 className="text-align-center">Personalization</h2>
      <div className="justify-center">
        <img alt="PMI" className="cep-image" src={PersonalizationImage} />
      </div>
      <h3>Using individualised attention to drive emotional connection. Demonstrating that you understand the customer's specific needs and circumstances and will adapt the experience accordingly is now the expected norm.</h3>

      <h2 className="text-align-center">Empathy</h2>
      <div className="justify-center">
        <img alt="PMI" className="cep-image" src={EmpathyImage} />
      </div>
      <h3>Achieving an understanding of the customer's circumstances to drive deep rapport. It is time for all firms to show that they care, that the safety and wellbeing of their employees and customers is their prime concern.</h3>
    </div>
  )
}

export default Cep;
